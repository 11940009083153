import './style.css'
import { Pane } from 'tweakpane'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js'
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js'
import { Vector3 } from 'three'

var matCaps = [{ key: '0b', mat: new URL('./assets/matcaps/0b.png', import.meta.url) },
{ key: '0l', mat: new URL('./assets/matcaps/0l.png', import.meta.url) },
{ key: '0pa', mat: new URL('./assets/matcaps/0pa.png', import.meta.url) },
{ key: '0pi', mat: new URL('./assets/matcaps/0pi.png', import.meta.url) },
{ key: '0s', mat: new URL('./assets/matcaps/0s.png', import.meta.url) },
{ key: '0t', mat: new URL('./assets/matcaps/0t.png', import.meta.url) },
{ key: '1', mat: new URL('./assets/matcaps/1.png', import.meta.url) },
{ key: '1b', mat: new URL('./assets/matcaps/1b.png', import.meta.url) },
{ key: '1l', mat: new URL('./assets/matcaps/1l.png', import.meta.url) },
{ key: '1pa', mat: new URL('./assets/matcaps/1pa.png', import.meta.url) },
{ key: '1pi', mat: new URL('./assets/matcaps/1pi.png', import.meta.url) },
{ key: '1t', mat: new URL('./assets/matcaps/1t.png', import.meta.url) },
{ key: '2', mat: new URL('./assets/matcaps/2.png', import.meta.url) },
{ key: '3', mat: new URL('./assets/matcaps/3.png', import.meta.url) },
{ key: '4', mat: new URL('./assets/matcaps/4.png', import.meta.url) },
{ key: '5', mat: new URL('./assets/matcaps/5.png', import.meta.url) }];

/**
 * Base
 */

const aboutModal = document.querySelector('.modal-container')
aboutModal.addEventListener('click', (e) => {
    gsap.to(aboutButton, { opacity: 1, duration: 0.5, delay: 1 })
    gsap.to(aboutModal, { translateY: '110%', duration: 0.4, ease: 'elastic.in(1, 1.1)' })
    gsap.to(aboutModal, { opacity: 0, duration: 0.25, delay: 0.35 })
    gsap.delayedCall(0.4, () => { aboutModal.style.display = 'none' })
    aboutModal.style.pointerEvents = 'none'
    pophigh.play()
    secretShowing = false;
    modalBlur(secretShowing);
})
const aboutButton = document.querySelector('.about')
aboutButton.addEventListener('click', (e) => {
    gsap.to(aboutButton, { opacity: 0, duration: 0.15 })
    gsap.to(aboutModal, { translateY: '0%', duration: 0.65, ease: 'elastic(1, 1.1)' })
    gsap.to(aboutModal, { opacity: 1, duration: 0.1 })
    aboutModal.style.pointerEvents = 'all'
    aboutModal.style.display = 'flex'
    boing.play()
    secretShowing = true;
    modalBlur(secretShowing);
})

// Canvas
let poplow = new Audio(new URL('./assets/sounds/poplow.mp3', import.meta.url));
let pophigh = new Audio(new URL('./assets/sounds/pophigh.mp3', import.meta.url));
let boing = new Audio(new URL('./assets/sounds/boing.mp3', import.meta.url));
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

const rootGroup = new THREE.Group()
scene.add(rootGroup)
const nonThemeGroup = new THREE.Group()
rootGroup.add(nonThemeGroup)

// Params
if (localStorage.getItem("stuffleemakes-theme") == null) {
    localStorage.setItem("stuffleemakes-theme", 0)
}

let casterObjects = []
const themes = [
    { title: '0t', speaker: '0s', pattern: '0pi', waves: '0b', particle: '0pa', letter: '0l', bg: '#e8dde2', outline: '#ffe8fe' },
    { title: '2', speaker: '2', pattern: '2', pinata: '2', waves: '2', particle: '2', letter: '2', bg: '#111111', outline: '#797979' },
    { title: '4', speaker: '4', pattern: '4', pinata: '4', waves: '4', particle: '4', letter: '4', bg: '#dfdfdf', outline: '#fffde7' },
    { title: '5', speaker: '5', pattern: '5', pinata: '5', waves: '5', particle: '5', letter: '5', bg: '#c7d6e2', outline: '#b2ffff' }
]

const parameters = {
    fontName: 'kaoly',
    fontSize: 0.5,
    curveSegments: 50,
    bevelDepth: 0.15,
    bevelThickness: 0.01,
    bevelSize: 0.01,
    bevelOffset: 0,
    theme: localStorage.getItem("stuffleemakes-theme"),
    currentTheme: themes[localStorage.getItem("stuffleemakes-theme")],
    testBG: '#000000',
    testOutline: '#ffffff',
    breakPoint: 500,
    isSmallScreen: false
}

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

const checkScreenSize = () => {
    parameters.isSmallScreen = sizes.width <= parameters.breakPoint
}
checkScreenSize()

document.body.style.backgroundColor = parameters.currentTheme.bg

/**
 * Loaders
 */
let loaded = false
const loadingIndicator = document.querySelector('.loading-indicator')
const t = gsap.timeline({ repeat: -1, repeatDelay: 0.5 })
t.to(loadingIndicator, { scale: 0.75, duration: 1, ease: "elastic.out(0.65, 0.3)" })
t.to(loadingIndicator, { delay: 0.25, scale: 0.65, duration: 1, ease: "elastic.out(0.65, 0.3)" })

const loadingManager = new THREE.LoadingManager(
    () => {
        if (loaded) { return }
        updateThemeRing(themeObjects[parameters.theme])
        gsap.to(loadingIndicator, { opacity: 0, duration: 0.5 })
        gsap.to(aboutButton, { opacity: 1, duration: 0.5, delay: 2.5 })
        gsap.delayedCall(0.5, () => {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 0.5, value: 0 })
            fitCameraToCenteredObject(camera, rootGroup, 1.1, null)
            gsap.delayedCall(2, () => {
                loaded = true
            })
        })
    }
)
// loadingManager.onError = function (url) {
//     console.log('There was an error loading ' + url);
// };
// loadingManager.onStart = function (url, itemsLoaded, itemsTotal) {
//     console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
// };
// loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
//     console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
// };

// Texture loader
let titleMaterial, speakerMaterial, patternsMaterial, pinataMaterial, wavesMaterial, particleMaterial, letterMaterial, pastelMaterial, murderMaterial, boneMaterial, funMaterial, titleTexture, speakerTexture, patternsTexture, pinataTexture, wavesTexture, particleTexture, letterTexture
const textureLoader = new THREE.TextureLoader(loadingManager)

const loadTextures = () => {
    if (titleTexture) {
        titleTexture.dispose()
        titleMaterial.dispose()
        titleMesh.material.matcap.dispose()
        titleMesh.material.dispose()
        speakerTexture.dispose()
        speakerMaterial.dispose()
        patternsTexture.dispose()
        patternsMaterial.dispose()
        linkSpeaker.material.matcap.dispose()
        linkSpeaker.material.dispose()
        // pinataTexture.dispose()
        // pinataMaterial.dispose()
        // linkPinata.material.matcap.dispose()
        // linkPinata.material.dispose()
        wavesTexture.dispose()
        wavesMaterial.dispose()
        linkWaves.material.matcap.dispose()
        linkWaves.material.dispose()
        particleTexture.dispose()
        particleMaterial.dispose()
        linkParticles.material.matcap.dispose()
        linkParticles.material.dispose()
        letterTexture.dispose()
        letterMaterial.dispose()
        linkText.material.matcap.dispose()
        linkText.material.dispose()
    }
    titleTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == parameters.currentTheme.title })[0].mat)
    titleMaterial = new THREE.MeshMatcapMaterial({ matcap: titleTexture })
    speakerTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == parameters.currentTheme.speaker })[0].mat)
    speakerMaterial = new THREE.MeshMatcapMaterial({ matcap: speakerTexture })
    patternsTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == parameters.currentTheme.pattern })[0].mat)
    patternsMaterial = new THREE.MeshMatcapMaterial({ matcap: patternsTexture })
    // pinataTexture = textureLoader.load('assets/matcaps/' + parameters.currentTheme.pinata + '.png')
    // pinataMaterial = new THREE.MeshMatcapMaterial({matcap:pinataTexture})
    wavesTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == parameters.currentTheme.waves })[0].mat)
    wavesMaterial = new THREE.MeshMatcapMaterial({ matcap: wavesTexture })
    particleTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == parameters.currentTheme.particle })[0].mat)
    particleMaterial = new THREE.MeshMatcapMaterial({ matcap: particleTexture })
    letterTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == parameters.currentTheme.letter })[0].mat)
    letterMaterial = new THREE.MeshMatcapMaterial({ matcap: letterTexture })
}

const reSetTextures = () => {
    loadTextures()
    linkWaves.material = wavesMaterial
    // linkPinata.material = pinataMaterial
    linkParticles.material = particleMaterial
    linkPatterns.material = patternsMaterial
    linkSpeaker.material = speakerMaterial
    linkText.material = letterMaterial
    titleMesh.material = titleMaterial
}
loadTextures()

let linkText, linkSpeaker, linkPinata, linkWaves, linkParticles, linkPatterns
const gltfLoader = new GLTFLoader(loadingManager)
let titleMesh
const fontLoader = new FontLoader(loadingManager)

const loadEverythingElse = () => {
    const yOffset = titleMesh.position.y

    gltfLoader.load(
        new URL('./assets/models/speaker.glb', import.meta.url).href,
        (gltf) => {
            linkSpeaker = gltf.scene.children[0]
            nonThemeGroup.add(linkSpeaker)
            if (parameters.isSmallScreen) {
                linkSpeaker.position.set(-0.45, yOffset + -1.8, 0)
            }
            else {
                linkSpeaker.position.set(-2.25, yOffset + 0.8, 0)
            }
            linkSpeaker.rotation.x = -Math.PI * 0.1
            linkSpeaker.scale.set(0.13, 0.13, 0.13)
            linkSpeaker.material = speakerMaterial
            linkSpeaker.name = 'linkSpeaker'
            linkSpeaker.userData.s = 0.13
            // linkSpeaker.userData.u = 'https://stuffleemakes.com/viz/'
            linkSpeaker.userData.u = 'audio.html'
            casterObjects.push(linkSpeaker)
        }
    )

    gltfLoader.load(
        new URL('./assets/models/pattern.glb', import.meta.url).href,
        (gltf) => {
            linkPatterns = gltf.scene.children[0]
            nonThemeGroup.add(linkPatterns)
            if (parameters.isSmallScreen) {
                linkPatterns.position.set(-0.375, yOffset + -0.9, 0)
            }
            else {
                linkPatterns.position.set(-1.15, yOffset + 0.85, 0)
            }
            linkPatterns.rotation.z = -Math.PI * 0.1
            linkPatterns.scale.set(0.14, 0.14, 0.1)
            linkPatterns.material = patternsMaterial
            linkPatterns.name = 'linkPatterns'
            linkPatterns.userData.s = 0.13
            linkPatterns.userData.u = 'patterns-v2.html'
            casterObjects.push(linkPatterns)
        }
    )

    fontLoader.load('gentilis_regular.typeface.json',
        (font) => {
            const geometry = new TextGeometry(
                'T',
                {
                    font: font,
                    size: 0.575,
                    height: 0.1,
                    curveSegments: 16,
                    bevelEnabled: false,
                    bevelThickness: 0.01,
                    bevelSize: 0.01,
                    bevelOffset: 0
                }
            )
            geometry.center()
            linkText = new THREE.Mesh(geometry, letterMaterial)
            nonThemeGroup.add(linkText)
            if (parameters.isSmallScreen) {
                linkText.position.set(0, yOffset + -2.5, 0)
                linkText.scale.set(1.075, 1.075, 1.075)
            }
            else {
                linkText.position.set(0, yOffset + 0.825, 0)
            }
            linkText.name = 'linkText'
            linkText.userData.s = 1
            linkText.userData.u = 'typography-sampling.html'
            casterObjects.push(linkText)
        })

    gltfLoader.load(
        new URL('./assets/models/waves.glb', import.meta.url).href,
        (gltf) => {
            linkWaves = gltf.scene.children[0]
            nonThemeGroup.add(linkWaves)
            if (parameters.isSmallScreen) {
                linkWaves.position.set(0.5, yOffset + -1.8, 0)
                linkWaves.scale.set(0.9, 0.9, 0.9)
            }
            else {
                linkWaves.position.set(1.15, yOffset + 0.84, 0)
            }
            linkWaves.rotation.x = -Math.PI * 0.2
            linkWaves.scale.set(0.315, 0.315, 0.315)
            linkWaves.material = wavesMaterial
            linkWaves.name = 'linkWaves'
            linkWaves.userData.s = 0.315
            linkWaves.userData.u = 'shaders-wave.html'
            casterObjects.push(linkWaves)
        }
    )
    // gltfLoader.load(
    //     'assets/models/pinata.glb',
    //     (gltf) =>
    //     {
    //         linkPinata = gltf.scene.children[0]
    //         nonThemeGroup.add(linkPinata)
    //         if(parameters.isSmallScreen) {
    //             linkPinata.position.set(0,yOffset + -2.85,0)
    //         }
    //         else {
    //             linkPinata.position.set(0,yOffset + 0.8,0)
    //         }
    //         linkPinata.scale.set(0.155,0.155,0.155)
    //         linkPinata.material = pinataMaterial
    //         linkPinata.name = 'linkPinata'
    //         linkPinata.userData.s = 0.155
    //         linkPinata.userData.u = 'https://poopypinata.com'
    //         casterObjects.push(linkPinata)
    //     }
    // )

    gltfLoader.load(
        new URL('./assets/models/particles.glb', import.meta.url).href,
        (gltf) => {
            linkParticles = gltf.scene.children[0]
            nonThemeGroup.add(linkParticles)
            if (parameters.isSmallScreen) {
                linkParticles.position.set(0.525, yOffset - 0.9, 0)
            }
            else {
                linkParticles.position.set(2.25, yOffset + 0.9, 0)
            }
            linkParticles.scale.set(0.075, 0.075, 0.075)
            linkParticles.material = particleMaterial
            linkParticles.name = 'linkParticles'
            linkParticles.userData.s = 0.075
            linkParticles.userData.u = 'particles.html'
            casterObjects.push(linkParticles)
        }
    )
}

const buildText = () => {
    checkScreenSize()
    var item = parameters.isSmallScreen ? new URL('./assets/models/mobile.glb', import.meta.url) : new URL('./assets/models/desktop.glb', import.meta.url);
    gltfLoader.load(item.href, (gltf) => {
        titleMesh = gltf.scene.children[0]
        nonThemeGroup.add(titleMesh)
        if (parameters.isSmallScreen) {
            titleMesh.position.y = 1.4
        }
        titleMesh.scale.set(1.1, 1.1, 1.1)
        titleMesh.material = titleMaterial
        loadEverythingElse()
        setupThemePicker()
    }
    )
}

/**
 * Theme picker
 */
let pastelMesh, murderMesh, boneMesh, funMesh, ringMesh
let themeObjects = []
const setupThemePicker = () => {
    const pastelTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == '0t' })[0].mat)
    pastelMaterial = new THREE.MeshMatcapMaterial({ matcap: pastelTexture })
    pastelMaterial.side = THREE.DoubleSide
    const murderTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == '2' })[0].mat)
    murderMaterial = new THREE.MeshMatcapMaterial({ matcap: murderTexture })
    murderMaterial.side = THREE.DoubleSide
    const boneTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == '4' })[0].mat)
    boneMaterial = new THREE.MeshMatcapMaterial({ matcap: boneTexture })
    boneMaterial.side = THREE.DoubleSide
    const funTexture = textureLoader.load(matCaps.filter((obj) => { return obj.key == '5' })[0].mat)
    funMaterial = new THREE.MeshMatcapMaterial({ matcap: funTexture })
    funMaterial.side = THREE.DoubleSide

    const offset = { x: (parameters.isSmallScreen ? -0.4 : 1.9), y: (parameters.isSmallScreen ? -1.75 : -0.35), s: 0.3 }
    const geometry = new THREE.SphereGeometry(0.08, 16, 16)
    pastelMesh = new THREE.Mesh(geometry, pastelMaterial)
    rootGroup.add(pastelMesh)
    pastelMesh.position.set(offset.x, offset.y, 0)
    murderMesh = new THREE.Mesh(geometry, murderMaterial)
    rootGroup.add(murderMesh)
    murderMesh.position.set(offset.x + offset.s, offset.y, 0)
    boneMesh = new THREE.Mesh(geometry, boneMaterial)
    rootGroup.add(boneMesh)
    boneMesh.position.set(offset.x + offset.s * 2, offset.y, 0)
    funMesh = new THREE.Mesh(geometry, funMaterial)
    rootGroup.add(funMesh)
    funMesh.position.set(offset.x + offset.s * 3, offset.y, 0)

    const rgeometry = new THREE.RingGeometry(0.1, 0.12, 64);
    ringMesh = new THREE.Mesh(rgeometry, pastelMaterial);
    rootGroup.add(ringMesh);
    ringMesh.position.set(offset.x, offset.y, 0)

    pastelMesh.name = 'theme0'
    pastelMesh.userData.s = 1
    murderMesh.name = 'theme1'
    murderMesh.userData.s = 1
    boneMesh.name = 'theme2'
    boneMesh.userData.s = 1
    funMesh.name = 'theme3'
    funMesh.userData.s = 1
    themeObjects.push(pastelMesh)
    themeObjects.push(murderMesh)
    themeObjects.push(boneMesh)
    themeObjects.push(funMesh)
    if (parameters.theme != 0) { casterObjects.push(pastelMesh) }
    if (parameters.theme != 1) { casterObjects.push(murderMesh) }
    if (parameters.theme != 2) { casterObjects.push(boneMesh) }
    if (parameters.theme != 3) { casterObjects.push(funMesh) }

    const gsTimeline = gsap.timeline({ repeat: -1 })
    gsTimeline.to(ringMesh.rotation, { y: Math.PI, duration: 2, ease: 'elastic.inOut', delay: 3 })
}

const updateThemeRing = (o) => {
    if (!ringMesh) { return }
    ringMesh.material = o.material
    ringMesh.position.x = o.position.x
    ringMesh.position.y = o.position.y
}

buildText()

const fitCameraToCenteredObject = function (camera, object, offset, orbitControls) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(object);

    var size = new THREE.Vector3();
    boundingBox.getSize(size);

    const fov = camera.fov * (Math.PI / 180);
    const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
    let dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
    let dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
    let cameraZ = Math.max(dx, dy);

    // offset the camera, if desired (to avoid filling the whole canvas)
    if (offset !== undefined && offset !== 0) cameraZ *= offset;

    const ftl = gsap.timeline()
    ftl.to(camera.position, { x: 0, duration: 1.5, ease: 'elastic.out(1, 0.6)', delay: 0.5 })
        .to(camera.position, { y: 0, duration: 1.5, ease: 'elastic.out(1, 0.6)', delay: 0.5 }, 0)
        .to(camera.position, { z: cameraZ, duration: 1.5, ease: 'elastic.out(1, 0.6)', delay: 0.5 }, 0)
    gsap.delayedCall(2, () => {
        camera.far = 500;
        camera.updateProjectionMatrix();
        applyDistanceBlur()
    })

    // set the far plane of the camera so that it easily encompasses the whole object
    const minZ = boundingBox.min.z;
    const cameraToFarEdge = (minZ < 0) ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    camera.updateProjectionMatrix();
    minDistance = cameraZ
    const v = new Vector3(1, 1, 1).unproject(camera)
    maxDistance = v.distanceTo(rootGroup.position)
    minDistance = cameraZ + maxDistance * 0.2
    applyDistanceBlur()

    if (orbitControls) {
        // set camera to rotate around the center
        orbitControls.target = new THREE.Vector3(0, 0, 0);

        // prevent camera from zooming out far enough to create far plane cutoff
        orbitControls.maxDistance = cameraToFarEdge * 2;
    }
}

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2.0, 2.0, 1.0)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    depthWrite: false,
    vertexShader: `
         void main() {
             gl_Position = vec4(position, 1.0);
         }
     `,
    fragmentShader: `
         uniform float uAlpha;
         uniform vec3 uColor;
         void main() {
             gl_FragColor = vec4(uColor, uAlpha);
         }
     `,
    uniforms: {
        uAlpha: { value: 1 },
        uColor: { value: new THREE.Color(parameters.currentTheme.bg) },
    }
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

window.addEventListener('resize', () => {
    const oldW = sizes.width
    const oldH = sizes.height
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    composer.setSize(sizes.width, sizes.height)
    composer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    outlinePass.resolution = new THREE.Vector2(sizes.width, sizes.height)
    // fxaaPass.uniforms.resolution.value.x = 1/(sizes.width*renderer.pixelRatio)
    // fxaaPass.uniforms.resolution.value.y = 1/(sizes.height*renderer.pixelRatio)
})

const raycaster = new THREE.Raycaster()
const mouse = new THREE.Vector2()
window.addEventListener('pointermove', (e) => {
    if (e.isPrimary === false) return;
    if (!loaded) { return; }
    if (secretShowing) { return; }

    mouse.x = (e.clientX / window.innerWidth) * 2 - 1
    mouse.y = - (e.clientY / window.innerHeight) * 2 + 1
    checkIntersection()

    //exponential falloff
    // let x = (cameraHorzLimit/Math.pow(2, cameraHorzLimit - Math.abs(x))) * Math.sign(mouse.x)
    // camera.position.x = cameraHorzLimit * mouse.x
    // camera.position.y = cameraVertLimit * mouse.y
    camera.position.lerp(new THREE.Vector3(cameraHorzLimit * mouse.x, cameraVertLimit * mouse.y, camera.position.z), 0.25)


    if (titleMesh) { applyDistanceBlur() }
})

const addSelectedObject = (object) => {
    outlinePass.selectedObjects = []
    if (object) { outlinePass.selectedObjects.push(object) }
}

const checkIntersection = () => {
    if (!loaded) { return }
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(casterObjects, true)
    if (intersects.length > 0) {
        if (outlinePass.selectedObjects.length == 0 || outlinePass.selectedObjects[0].name != outlinePass.selectedObjects[0].name) {
            if (outlinePass.selectedObjects.length > 0) { setObjectNotHovered(outlinePass.selectedObjects[0]) }
            addSelectedObject(intersects[0].object)
            setObjectHovered(outlinePass.selectedObjects[0])
            document.body.style.cursor = "pointer"
            poplow.play()
        }
    } else {
        if (outlinePass.selectedObjects.length > 0) { setObjectNotHovered(outlinePass.selectedObjects[0]) }
        addSelectedObject()
        document.body.style.cursor = "default"
    }
}

let mouseIsDown = false
window.addEventListener('pointerdown', () => {
    mouseIsDown = true
})

window.addEventListener('pointerup', () => {
    mouseIsDown = false
})

canvas.addEventListener('pointerenter', (e) => {
    mouse.x = (e.clientX / window.innerWidth) * 2 - 1
    mouse.y = - (e.clientY / window.innerHeight) * 2 + 1
})

const tryFunc = (func) => {
    if (mouseIsDown) {
        setTimeout(() => {
            if (!mouseIsDown) { func() }
        }, 100)
    }
    else {
        func()
    }
}

window.addEventListener('click', (e) => {
    mouse.x = (e.clientX / window.innerWidth) * 2 - 1
    mouse.y = - (e.clientY / window.innerHeight) * 2 + 1
    checkIntersection()
    if (outlinePass.selectedObjects.length == 0) { return }
    const obj = outlinePass.selectedObjects[0]
    const name = obj.name
    if (name.startsWith('theme')) {
        const index = parseInt(name.substr(-1))
        localStorage.setItem("stuffleemakes-theme", index)
        casterObjects = casterObjects.filter((e) => { return !e.name.startsWith('theme') })
        const filteredThemes = themeObjects.filter((e) => { return e.name != name })
        casterObjects = casterObjects.concat(filteredThemes)
        const oldColor = parameters.currentTheme.bg
        parameters.theme = index
        parameters.currentTheme = themes[parameters.theme]
        const newColor = parameters.currentTheme.bg
        const speed = 0.15
        gsap.delayedCall(speed, () => {
            reSetTextures()
            parameters.testBG = parameters.currentTheme.bg
            parameters.testOutline = parameters.currentTheme.outline
            outlinePass.visibleEdgeColor.set(parameters.currentTheme.outline)
            outlinePass.hiddenEdgeColor.set(parameters.currentTheme.outline)
            updateThemeRing(obj)
        })

        const gtl = gsap.timeline()
        gtl.to(nonThemeGroup.scale, { x: 0, duration: speed })
            .to(nonThemeGroup.scale, { y: 0, duration: speed }, 0)
            .to(nonThemeGroup.scale, { z: 0, duration: speed }, 0)
            .to(nonThemeGroup.rotation, { x: Math.PI * -0.5, duration: speed }, 0)
            .to(nonThemeGroup.scale, { x: 1, duration: 0.75, ease: 'elastic.out(1, 0.6)' }, speed)
            .to(nonThemeGroup.scale, { y: 1, duration: 0.75, ease: 'elastic.out(1, 0.6)' }, speed)
            .to(nonThemeGroup.scale, { z: 1, duration: 0.75, ease: 'elastic.out(1, 0.6)' }, speed)
            .to(nonThemeGroup.rotation, { x: 0, duration: 0.75, ease: 'elastic.out(1, 0.6)' }, speed)
            .fromTo(parameters.currentTheme, { bg: oldColor }, {
                bg: newColor, duration: speed * 4, onUpdate: function () {
                    renderer.setClearColor(parameters.currentTheme.bg)
                }
            }, speed)
        boing.play()
    }
    else {
        gsap.delayedCall(0.25, () => {
            window.location.href = obj.userData.u
        })
        pophigh.play()
    }
    setObjectNotHovered(obj)
    addSelectedObject()
})

let gsHover
const setObjectHovered = (o) => {
    gsHover = gsap.timeline()
    gsHover.to(o.scale, { x: o.userData.s + o.userData.s * 0.3, duration: 0.65, ease: 'elastic(1, 0.4)' })
        .to(o.scale, { y: o.userData.s + o.userData.s * 0.3, duration: 0.65, ease: 'elastic(1, 0.4)' }, 0)
        .to(o.scale, { z: o.userData.s + o.userData.s * 0.3, duration: 0.65, ease: 'elastic(1, 0.4)' }, 0)
}

const setObjectNotHovered = (o) => {
    gsHover = gsap.timeline()
    gsHover.to(o.scale, { x: o.userData.s, duration: 0.65, ease: 'elastic(1, 0.2)' })
        .to(o.scale, { y: o.userData.s, duration: 0.65, ease: 'elastic(1, 0.2)' }, 0)
        .to(o.scale, { z: o.userData.s, duration: 0.65, ease: 'elastic(1, 0.2)' }, 0)
}

/**
 * Camera
 */
// Base camera
let cameraHorzLimit = 11
let cameraVertLimit = 11
let minDistance = -1
let maxDistance = -1
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.set((Math.random() - 1) * 5, (Math.random() - 1) * 5, Math.random() * 10 + cameraHorzLimit + 5)
camera.lookAt(rootGroup.position)
scene.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.outputEncoding = THREE.sRGBEncoding
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(parameters.currentTheme.bg)


/**
 * Postprocessing
 */
const composer = new EffectComposer(renderer)
composer.antialias = true
composer.setSize(sizes.width, sizes.height)
composer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
const renderPass = new RenderPass(scene, camera)
composer.addPass(renderPass)
const outlinePass = new OutlinePass(new THREE.Vector2(sizes.width, sizes.height), scene, camera)
outlinePass.edgeThickness = 4
outlinePass.edgeStrength = 10
outlinePass.visibleEdgeColor.set(parameters.currentTheme.outline)
outlinePass.hiddenEdgeColor.set(parameters.currentTheme.outline)
composer.addPass(outlinePass)
const horizontalBlurPass = new ShaderPass(HorizontalBlurShader)
horizontalBlurPass.uniforms.h.value = 0 / sizes.width
composer.addPass(horizontalBlurPass)
const verticalBlurPass = new ShaderPass(VerticalBlurShader)
verticalBlurPass.uniforms.v.value = 0 / sizes.height
composer.addPass(verticalBlurPass)
// const fxaaPass = new ShaderPass( FXAAShader )
// fxaaPass.uniforms.resolution.value.x = 1/(sizes.width*renderer.pixelRatio)
// fxaaPass.uniforms.resolution.value.y = 1/(sizes.height*renderer.pixelRatio)
// composer.addPass( fxaaPass )

const applyDistanceBlur = () => {
    let d = camera.position.distanceTo(rootGroup.position)

    d = Math.max(0, remap(d, minDistance, maxDistance, 0, 0.75))
    horizontalBlurPass.uniforms.h.value = d / sizes.width
    verticalBlurPass.uniforms.v.value = d / sizes.height
    camera.lookAt(rootGroup.position)
}

applyDistanceBlur();

const modalBlur = (t) => {
    var u = {hv: (secretShowing ? 3/sizes.width : 0), vv: (secretShowing ? 3/sizes.height : 0) };
    gsap.to(u, {hv: (secretShowing ? 3/sizes.width : 0), duration: 0.4, onUpdate: ()=>{ horizontalBlurPass.uniforms.h.value = u.hv; } });
    gsap.to(u, {vv: (secretShowing ? 3/sizes.height : 0), duration: 0.4, onUpdate: ()=>{ verticalBlurPass.uniforms.v.value = u.vv; } });
}

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    if (linkSpeaker) { linkSpeaker.rotation.y += 0.0015 }
    if (linkPinata) { linkPinata.rotation.y -= 0.00125 }
    if (linkWaves) { linkWaves.rotation.y -= 0.00175 }
    if (linkText) { linkText.rotation.y += 0.0025 }
    if (linkParticles) { linkParticles.rotation.y += 0.002 }
    if (linkPatterns) { linkPatterns.rotation.y -= 0.002 }

    if (!loaded) { applyDistanceBlur() }

    // Render
    renderer.render(scene, camera)
    composer.render()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

function remap(number, inMin, inMax, outMin, outMax) {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

const secretTunnels = document.getElementById('secret-tunnels');
var secretShowing = false;
document.addEventListener('keydown', (e) => {
    if (e.key === "s") { 
        secretShowing = !secretShowing;
        modalBlur(secretShowing);
        gsap.fromTo(secretTunnels, {scale: (secretShowing ? 0.5 : 1)}, { scale: (secretShowing ? 1 : 0.5), duration: 0.4, ease: (secretShowing ? 'elastic.out(1, 1.1)' : 'elastic.in(1, 1.1)') });
        gsap.to(secretTunnels, { opacity: (secretShowing ? 1 : 0), duration: 0.25, delay: (secretShowing ? 0 : 0.35) });
        secretTunnels.style.pointerEvents = secretShowing ? 'all' : 'none';
        if (secretShowing) {
            secretTunnels.style.display = 'flex';
        }
        else {
            gsap.delayedCall(0.4, () => { 
                secretTunnels.style.display = 'none';
            });
        }
    }
});